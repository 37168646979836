import { Box } from "@mui/material";

const ErrorImage = () => {
    return (
        <Box className='qa-error-image'>
            <svg width="360" height="360" viewBox="0 0 360 360" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M63.9118 300.385C65.4908 300.499 67.0307 300.93 68.4396 301.652C69.8485 302.374 71.0974 303.373 72.1119 304.588C74.1291 307.033 75.5575 309.909 76.2867 312.994C77.8651 318.896 76.8938 325.873 75.2127 331.748C68.8244 329.459 64.8738 322.642 63.0712 319.055C60.2694 313.395 58.5976 301.441 63.8838 300.376" fill="#FF725E"/>
                <path d="M63.9118 300.385C65.4908 300.499 67.0307 300.93 68.4396 301.652C69.8485 302.374 71.0974 303.373 72.1119 304.588C74.1291 307.033 75.5575 309.909 76.2867 312.994C77.8651 318.896 76.8938 325.873 75.2127 331.748C68.8244 329.459 64.8738 322.642 63.0712 319.055C60.2694 313.395 58.5976 301.441 63.8838 300.376" fill="url(#paint0_linear_2002_99735)"/>
                <path d="M84.4124 338.912C83.4531 337.424 82.9046 335.709 82.8225 333.94C82.7405 332.172 83.1279 330.414 83.9454 328.843C84.7885 327.288 85.933 325.916 87.3121 324.808C88.6911 323.7 90.2771 322.878 91.9774 322.39C93.5371 321.904 95.349 321.708 96.7033 322.614C97.3137 323.076 97.7892 323.693 98.0799 324.401C98.3707 325.109 98.4662 325.882 98.3564 326.639C98.1112 328.157 97.4665 329.582 96.4884 330.767C93.3784 334.98 89.6052 338.482 84.347 338.912" fill="#FF725E"/>
                <path d="M85.1191 358.645C84.9805 358.249 84.8835 357.839 84.8296 357.422C84.6614 356.544 84.4373 355.414 84.1664 354.06C83.3281 350.352 83.0947 346.532 83.4753 342.75C83.9157 338.948 85.3634 335.334 87.6688 332.28C88.3731 331.367 89.1854 330.542 90.0877 329.824C90.3329 329.629 90.5886 329.448 90.8536 329.282C90.9378 329.212 91.0323 329.155 91.1338 329.114C89.9524 330.123 88.8731 331.246 87.9116 332.467C85.7278 335.524 84.3527 339.084 83.9143 342.815C83.3165 347.167 84.0544 351.155 84.484 354.022C84.7081 355.451 84.8949 356.609 84.9977 357.413C85.0712 357.82 85.1118 358.232 85.1191 358.645Z" fill="#263238"/>
                <path d="M65.9683 309.383C66.0749 309.53 66.1628 309.69 66.2298 309.859C66.3979 310.233 66.622 310.7 66.8835 311.288C67.4533 312.53 68.2471 314.333 69.2091 316.565C71.133 321.039 73.7108 327.259 76.4006 334.189C79.0904 341.119 81.3786 347.442 82.9383 352.046C83.7415 354.344 84.3672 356.211 84.8062 357.51C85.0023 358.126 85.1518 358.621 85.2732 359.004C85.3398 359.176 85.3868 359.354 85.4133 359.536C85.3185 359.378 85.2433 359.209 85.1891 359.032C85.049 358.658 84.8716 358.173 84.6381 357.566L82.6674 352.149C80.9956 347.582 78.6608 341.268 75.9803 334.348C73.2998 327.427 70.7595 321.188 68.9102 316.686C67.9763 314.473 67.2571 312.67 66.7248 311.353C66.4819 310.756 66.2858 310.279 66.1364 309.897C66.0662 309.73 66.01 309.558 65.9683 309.383Z" fill="#263238"/>
                <path d="M77.5279 345.583C75.6062 342.384 72.9505 339.687 69.7804 337.718C66.6103 335.748 63.0168 334.56 59.2971 334.254C57.4292 334.124 55.1316 334.441 54.2911 336.122C53.4505 337.803 54.5713 339.858 55.8601 341.222C58.5985 344.086 62.1238 346.076 65.9914 346.941C69.859 347.805 73.896 347.505 77.5933 346.078" fill="#FF725E"/>
                <path d="M77.5279 345.583C75.6062 342.384 72.9505 339.687 69.7804 337.718C66.6103 335.748 63.0168 334.56 59.2971 334.254C57.4292 334.124 55.1316 334.441 54.2911 336.122C53.4505 337.803 54.5713 339.858 55.8601 341.222C58.5985 344.086 62.1238 346.076 65.9914 346.941C69.859 347.805 73.896 347.505 77.5933 346.078" fill="url(#paint1_linear_2002_99735)"/>
                <path d="M62.1712 339.323C62.5672 339.303 62.9641 339.319 63.3573 339.369C63.8178 339.394 64.2763 339.447 64.7302 339.528C65.2626 339.631 65.8883 339.687 66.5421 339.874C67.2729 340.028 67.9934 340.228 68.6995 340.471C69.5177 340.734 70.3192 341.046 71.0998 341.405C72.8953 342.21 74.5936 343.215 76.1619 344.403C77.712 345.619 79.1161 347.011 80.346 348.55C80.8771 349.22 81.3668 349.922 81.8123 350.652C82.215 351.282 82.5829 351.933 82.9144 352.604C83.195 353.149 83.4444 353.711 83.6615 354.285C83.8488 354.71 84.0079 355.146 84.1379 355.592C84.2762 355.965 84.3794 356.349 84.4461 356.741C84.3433 356.741 83.895 355.135 82.6342 352.744C82.2909 352.101 81.9136 351.478 81.5041 350.876C81.049 350.168 80.5564 349.485 80.0284 348.83C77.5856 345.812 74.4703 343.406 70.9317 341.807C70.1192 341.452 69.344 341.116 68.5875 340.873C67.893 340.62 67.1852 340.405 66.4674 340.229C63.8523 339.509 62.1618 339.425 62.1712 339.323Z" fill="#263238"/>
                <path d="M349.282 359.725C355.117 359.725 359.785 355.006 359.868 349.193C360.285 320.04 362.619 276.986 308.268 265.021C264.255 255.416 290.346 176.3 171.808 181.44C86.6981 185.147 109.289 257.607 43.1843 263.673C-0.829636 267.718 -1.24644 324 0.504118 349.783C0.837558 355.428 5.50571 359.809 11.0908 359.809H349.282V359.725Z" fill="url(#paint2_linear_2002_99735)" fill-opacity="0.2"/>
                <path d="M119.436 134.71H118.602V181.894H119.436V134.71Z" fill="url(#paint3_linear_2002_99735)"/>
                <path d="M119.539 86.1999L119.539 86.1999L119.54 86.1931C120.319 79.2063 126.65 74.19 133.551 74.9742C133.552 74.9743 133.553 74.9744 133.554 74.9745L316.58 96.5884C316.583 96.5887 316.585 96.589 316.588 96.5893C323.496 97.4786 328.468 103.777 327.691 110.773C327.691 110.775 327.69 110.777 327.69 110.779L313.982 229.164L313.981 229.171C313.202 236.158 306.872 241.174 299.971 240.39C299.969 240.39 299.968 240.39 299.967 240.39L116.925 218.774L116.918 218.773C110.043 217.99 105.05 211.608 105.83 204.588C105.83 204.587 105.831 204.586 105.831 204.585L119.539 86.1999Z" fill="white" stroke="url(#paint4_linear_2002_99735)" stroke-width="3.21429"/>
                <path d="M327.97 122.707L329.286 110.957C330.164 103.086 324.57 95.8806 316.783 94.9938L133.735 73.3772C125.948 72.4904 118.819 78.144 117.942 86.0146L116.626 97.7651L327.97 122.707Z" fill="url(#paint5_linear_2002_99735)"/>
                <path d="M138.012 89.7838C137.793 91.8901 135.819 93.442 133.735 93.2203C131.651 92.9986 130.116 91.0032 130.335 88.897C130.554 86.7908 132.529 85.2388 134.612 85.4605C136.696 85.6823 138.341 87.6776 138.012 89.7838Z" fill="white"/>
                <path d="M156.436 92.0011C156.216 94.1074 154.242 95.6593 152.158 95.4376C150.075 95.2159 148.539 93.2205 148.758 91.1143C148.978 89.0081 150.952 87.4561 153.036 87.6778C155.12 87.8995 156.655 89.7841 156.436 92.0011Z" fill="white"/>
                <path d="M175.521 94.2179C175.302 96.3242 173.328 97.8761 171.244 97.6544C169.16 97.4327 167.625 95.4373 167.844 93.3311C168.063 91.2249 170.038 89.6729 172.121 89.8946C174.205 90.1163 175.741 92.1117 175.521 94.2179Z" fill="white"/>
                <path d="M169.966 141.132C168.494 139.298 165.814 139.006 163.981 140.478C162.147 141.95 161.855 144.63 163.327 146.463L168.201 152.533L162.132 157.407C160.298 158.88 160.006 161.559 161.478 163.393C162.95 165.226 165.63 165.519 167.463 164.046L173.533 159.172L178.407 165.242C179.88 167.075 182.559 167.368 184.393 165.895C186.226 164.423 186.519 161.743 185.046 159.91L180.172 153.84L186.242 148.966C188.075 147.494 188.368 144.814 186.895 142.981C185.423 141.147 182.743 140.855 180.91 142.327L174.84 147.201L169.966 141.132Z" fill="#424347"/>
                <path d="M251.966 152.132C250.494 150.298 247.814 150.006 245.981 151.478C244.147 152.95 243.855 155.63 245.327 157.463L250.201 163.533L244.132 168.407C242.298 169.88 242.006 172.559 243.478 174.393C244.95 176.226 247.63 176.519 249.463 175.046L255.533 170.172L260.407 176.242C261.88 178.075 264.559 178.368 266.393 176.895C268.226 175.423 268.519 172.743 267.046 170.91L262.172 164.84L268.242 159.966C270.075 158.494 270.368 155.814 268.895 153.981C267.423 152.147 264.743 151.855 262.91 153.327L256.84 158.201L251.966 152.132Z" fill="#424347"/>
                <path d="M250.893 274.51H75.6685V310.558H250.893V274.51Z" fill="black"/>
                <path opacity="0.29" d="M250.893 274.51H75.6685V283.19H250.893V274.51Z" fill="#444444"/>
                <path d="M90.9355 274.51H106.919L102.625 283.311H86.6414L90.9355 274.51Z" fill="#F77E2D"/>
                <path d="M126.958 274.509H142.942L138.648 283.31H122.664L126.958 274.509Z" fill="#F77E2D"/>
                <path d="M162.981 274.51H178.965L174.671 283.311H158.687L162.981 274.51Z" fill="#F77E2D"/>
                <path d="M199.004 274.51H214.987L210.693 283.311H194.709L199.004 274.51Z" fill="#F77E2D"/>
                <path d="M250.893 274.509V274.75L246.718 283.31H230.734L235.028 274.509H250.893Z" fill="#F77E2D"/>
                <path d="M109.906 310.557H97.501V359.747H109.906V310.557Z" fill="url(#paint6_linear_2002_99735)"/>
                <path d="M109.901 310.557H107.038V359.747H109.901V310.557Z" fill="url(#paint7_linear_2002_99735)"/>
                <path d="M230.019 310.557H217.614V359.747H230.019V310.557Z" fill="url(#paint8_linear_2002_99735)"/>
                <path d="M230.02 310.557H226.322V359.747H230.02V310.557Z" fill="url(#paint9_linear_2002_99735)"/>
                <path d="M109.906 310.557H97.501V316.705H109.906V310.557Z" fill="url(#paint10_linear_2002_99735)"/>
                <path d="M230.019 310.557H217.614V316.705H230.019V310.557Z" fill="url(#paint11_linear_2002_99735)"/>
                <path d="M109.906 268.963H97.501V274.509H109.906V268.963Z" fill="url(#paint12_linear_2002_99735)"/>
                <path d="M109.901 268.963H107.038V274.509H109.901V268.963Z" fill="url(#paint13_linear_2002_99735)"/>
                <path d="M230.019 268.963H217.614V274.509H230.019V268.963Z" fill="url(#paint14_linear_2002_99735)"/>
                <path d="M230.02 268.963H226.322V274.509H230.02V268.963Z" fill="url(#paint15_linear_2002_99735)"/>
                <path d="M109.906 273.785H97.501V274.508H109.906V273.785Z" fill="url(#paint16_linear_2002_99735)"/>
                <path d="M230.019 273.785H217.614V274.508H230.019V273.785Z" fill="url(#paint17_linear_2002_99735)"/>
                <path d="M106.919 274.51L89.2649 310.558H75.6667V305.615L90.9348 274.51H106.919Z" fill="url(#paint18_linear_2002_99735)"/>
                <path d="M126.958 274.51L109.304 310.558H125.288L142.942 274.51H126.958Z" fill="url(#paint19_linear_2002_99735)"/>
                <path d="M178.965 274.51L161.311 310.558H145.328L162.981 274.51H178.965Z" fill="url(#paint20_linear_2002_99735)"/>
                <path d="M214.99 274.51L197.336 310.558H181.352L199.006 274.51H214.99Z" fill="url(#paint21_linear_2002_99735)"/>
                <path d="M235.025 274.51L217.371 310.558H233.355L250.889 274.751L235.025 274.51Z" fill="url(#paint22_linear_2002_99735)"/>
                <path d="M106.919 274.509L102.625 283.31H86.6414L90.9355 274.509H106.919Z" fill="url(#paint23_linear_2002_99735)"/>
                <path d="M142.944 274.509L138.65 283.31H122.666L126.96 274.509H142.944Z" fill="url(#paint24_linear_2002_99735)"/>
                <path d="M178.967 274.509L174.673 283.31H158.689L162.983 274.509H178.967Z" fill="url(#paint25_linear_2002_99735)"/>
                <path d="M214.992 274.509L210.697 283.31H194.714L199.008 274.509H214.992Z" fill="url(#paint26_linear_2002_99735)"/>
                <path d="M250.891 274.75L246.716 283.31H230.732L235.027 274.509L250.891 274.75Z" fill="url(#paint27_linear_2002_99735)"/>
                <path opacity="0.55" d="M247.793 290.182H80.5596V308.146H247.793V290.182Z" fill="url(#paint28_linear_2002_99735)"/>
                <path d="M105.086 272.08C111.068 271.096 115.128 265.397 114.154 259.351C113.181 253.305 107.543 249.201 101.561 250.184C95.5788 251.168 91.5186 256.867 92.4919 262.913C93.4653 268.96 99.1036 273.064 105.086 272.08Z" fill="black"/>
                <path d="M108.351 261.126C108.351 258.353 106.085 256.062 103.342 256.062C100.598 256.062 98.3318 258.353 98.3318 261.126C98.3318 263.899 100.598 266.19 103.342 266.19C106.085 266.19 108.351 263.899 108.351 261.126Z" fill="url(#paint29_linear_2002_99735)"/>
                <path d="M225.556 272.076C231.537 271.093 235.598 265.394 234.624 259.347C233.651 253.301 228.013 249.197 222.031 250.181C216.049 251.165 211.989 256.864 212.962 262.91C213.935 268.956 219.574 273.06 225.556 272.076Z" fill="black"/>
                <path d="M228.706 261.126C228.706 258.353 226.439 256.062 223.696 256.062C220.952 256.062 218.686 258.353 218.686 261.126C218.686 263.899 220.952 266.19 223.696 266.19C226.439 266.19 228.706 263.899 228.706 261.126Z" fill="url(#paint30_linear_2002_99735)"/>
                <path d="M342.377 354.802H268.509L279.874 327.202L284.451 316.035L285.714 313.004L287.765 308.058L288.239 307.101L292.816 295.934L293.605 293.86L301.655 274.078C303.076 270.568 308.126 270.568 309.547 274.078L317.597 293.86L318.386 295.934L322.963 307.101L323.437 308.058L325.489 313.004L326.751 316.035L331.329 327.202L342.377 354.802Z" fill="white"/>
                <path opacity="0.29" d="M273.402 342.836L268.509 354.801H342.377L337.484 342.836H273.402Z" fill="#444444"/>
                <path d="M347.271 347.623H262.67V359.747H347.271V347.623Z" fill="url(#paint31_linear_2002_99735)"/>
                <path d="M347.273 347.623H328.964V359.747H347.273V347.623Z" fill="url(#paint32_linear_2002_99735)"/>
                <path opacity="0.29" d="M339.543 347.622H329.126L320.761 327.043L316.184 315.875L314.921 312.844L312.869 307.899L312.395 306.941L307.818 295.774L307.029 293.7L300.242 277.109L301.505 273.918C302.925 270.408 307.976 270.408 309.396 273.918L317.446 293.7L318.235 295.774L322.813 306.941L323.286 307.899L325.338 312.844L326.601 315.875L331.178 327.043L339.543 347.622Z" fill="#444444"/>
                <path d="M322.816 306.781H288.092L292.669 295.614H318.239L322.816 306.781Z" fill="#262629"/>
                <path d="M331.181 327.042H279.884L284.461 315.875H326.604L331.181 327.042Z" fill="#262629"/>
                <path d="M322.816 306.781H312.399L307.822 295.614H318.239L322.816 306.781Z" fill="#262629"/>
                <path d="M331.179 327.042H320.604L316.184 315.875H326.601L331.179 327.042Z" fill="#262629"/>
                <path d="M122.88 336.004C122.254 336.049 121.643 336.22 121.084 336.506C120.525 336.793 120.03 337.189 119.628 337.671C118.828 338.641 118.261 339.781 117.972 341.005C117.346 343.346 117.731 346.114 118.398 348.444C120.932 347.536 122.499 344.832 123.214 343.409C124.325 341.164 124.988 336.422 122.891 336" fill="#FF725E"/>
                <path d="M122.88 336.004C122.254 336.049 121.643 336.22 121.084 336.506C120.525 336.793 120.03 337.189 119.628 337.671C118.828 338.641 118.261 339.781 117.972 341.005C117.346 343.346 117.731 346.114 118.398 348.444C120.932 347.536 122.499 344.832 123.214 343.409C124.325 341.164 124.988 336.422 122.891 336" fill="url(#paint33_linear_2002_99735)"/>
                <path d="M114.749 351.286C115.129 350.696 115.347 350.016 115.379 349.315C115.412 348.613 115.258 347.916 114.934 347.293C114.599 346.676 114.145 346.132 113.598 345.692C113.051 345.253 112.422 344.927 111.748 344.733C111.129 344.54 110.411 344.462 109.873 344.822C109.631 345.005 109.443 345.25 109.327 345.531C109.212 345.811 109.174 346.118 109.218 346.418C109.315 347.02 109.571 347.586 109.959 348.056C111.192 349.727 112.689 351.116 114.775 351.286" fill="#FF725E"/>
                <path d="M114.468 359.115C114.523 358.958 114.562 358.795 114.583 358.63C114.65 358.282 114.739 357.834 114.846 357.296C115.179 355.826 115.271 354.311 115.12 352.81C114.945 351.302 114.371 349.869 113.457 348.657C113.177 348.295 112.855 347.968 112.497 347.683C112.4 347.606 112.299 347.534 112.193 347.468C112.16 347.44 112.123 347.418 112.082 347.401C112.551 347.802 112.979 348.247 113.36 348.731C114.227 349.944 114.772 351.356 114.946 352.836C115.183 354.562 114.89 356.144 114.72 357.282C114.631 357.848 114.557 358.308 114.516 358.626C114.487 358.788 114.471 358.951 114.468 359.115Z" fill="#263238"/>
                <path d="M122.064 339.571C122.022 339.63 121.987 339.693 121.96 339.76C121.894 339.908 121.805 340.094 121.701 340.327C121.475 340.82 121.16 341.535 120.778 342.42C120.015 344.195 118.993 346.662 117.926 349.411C116.859 352.16 115.951 354.668 115.333 356.494C115.014 357.405 114.766 358.146 114.592 358.661C114.514 358.906 114.455 359.102 114.406 359.254C114.38 359.322 114.361 359.393 114.351 359.465C114.388 359.402 114.418 359.335 114.44 359.265C114.495 359.117 114.566 358.924 114.658 358.684L115.44 356.535C116.103 354.723 117.029 352.219 118.093 349.474C119.156 346.729 120.163 344.254 120.897 342.468C121.267 341.59 121.553 340.875 121.764 340.353C121.86 340.116 121.938 339.927 121.997 339.775C122.025 339.709 122.047 339.641 122.064 339.571Z" fill="#263238"/>
                <path d="M117.478 353.937C118.241 352.668 119.294 351.598 120.552 350.817C121.809 350.035 123.234 349.564 124.71 349.443C125.451 349.391 126.362 349.517 126.696 350.184C127.029 350.851 126.584 351.666 126.073 352.207C124.987 353.343 123.589 354.132 122.055 354.475C120.52 354.818 118.919 354.699 117.452 354.133" fill="#FF725E"/>
                <path d="M117.478 353.937C118.241 352.668 119.294 351.598 120.552 350.817C121.809 350.035 123.234 349.564 124.71 349.443C125.451 349.391 126.362 349.517 126.696 350.184C127.029 350.851 126.584 351.666 126.073 352.207C124.987 353.343 123.589 354.132 122.055 354.475C120.52 354.818 118.919 354.699 117.452 354.133" fill="url(#paint34_linear_2002_99735)"/>
                <path d="M123.568 351.447C123.411 351.439 123.253 351.446 123.097 351.466C122.914 351.475 122.733 351.497 122.553 351.529C122.341 351.569 122.093 351.592 121.834 351.666C121.544 351.727 121.258 351.806 120.978 351.903C120.654 352.007 120.336 352.131 120.026 352.273C119.314 352.592 118.64 352.991 118.018 353.462C117.403 353.945 116.846 354.497 116.358 355.107C116.148 355.373 115.953 355.651 115.777 355.941C115.617 356.191 115.471 356.449 115.34 356.715C115.228 356.932 115.129 357.154 115.043 357.382C114.969 357.551 114.906 357.724 114.854 357.901C114.799 358.048 114.758 358.201 114.732 358.356C114.773 358.356 114.951 357.719 115.451 356.771C115.587 356.516 115.737 356.269 115.899 356.03C116.08 355.749 116.275 355.478 116.484 355.218C117.453 354.021 118.689 353.067 120.093 352.433C120.415 352.292 120.722 352.158 121.023 352.062C121.298 351.962 121.579 351.876 121.863 351.806C122.901 351.521 123.571 351.488 123.568 351.447Z" fill="#263238"/>
                <defs>
                    <linearGradient id="paint0_linear_2002_99735" x1="77.0861" y1="306.807" x2="60.1587" y2="307.183" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_2002_99735" x1="77.5933" y1="336.933" x2="53.7288" y2="338.719" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_2002_99735" x1="180" y1="181.2" x2="180" y2="359.809" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#5304D6" stopOpacity="0.64"/>
                        <stop offset="1" stopColor="#B413FF" stopOpacity="0.4"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_2002_99735" x1="118.602" y1="181.894" x2="119.649" y2="181.892" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#148F2B"/>
                        <stop offset="1" stopColor="#0AB38A"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_2002_99735" x1="216.76" y1="73.2861" x2="216.76" y2="242.078" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6720FF"/>
                        <stop offset="1" stopColor="#0D069D" stopOpacity="0.94"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear_2002_99735" x1="223.001" y1="73.2856" x2="223.001" y2="122.707" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6720FF"/>
                        <stop offset="1" stopColor="#0D069D" stopOpacity="0.94"/>
                    </linearGradient>
                    <linearGradient id="paint6_linear_2002_99735" x1="109.906" y1="320.641" x2="97.2895" y2="320.774" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint7_linear_2002_99735" x1="107.038" y1="359.747" x2="110.634" y2="359.735" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint8_linear_2002_99735" x1="230.019" y1="320.641" x2="217.403" y2="320.774" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint9_linear_2002_99735" x1="226.322" y1="359.747" x2="230.967" y2="359.727" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint10_linear_2002_99735" x1="97.501" y1="316.705" x2="112.872" y2="314.894" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint11_linear_2002_99735" x1="217.614" y1="316.705" x2="232.985" y2="314.894" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint12_linear_2002_99735" x1="97.501" y1="274.509" x2="112.824" y2="272.508" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint13_linear_2002_99735" x1="107.038" y1="274.509" x2="110.631" y2="274.401" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint14_linear_2002_99735" x1="217.614" y1="274.509" x2="232.937" y2="272.508" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint15_linear_2002_99735" x1="226.322" y1="274.509" x2="230.96" y2="274.329" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint16_linear_2002_99735" x1="97.501" y1="274.508" x2="105.281" y2="266.716" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint17_linear_2002_99735" x1="217.614" y1="274.508" x2="225.394" y2="266.716" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint18_linear_2002_99735" x1="106.919" y1="281.9" x2="75.1723" y2="283.051" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint19_linear_2002_99735" x1="142.942" y1="281.9" x2="108.779" y2="283.234" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint20_linear_2002_99735" x1="178.965" y1="281.9" x2="144.803" y2="283.234" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint21_linear_2002_99735" x1="214.99" y1="281.9" x2="180.827" y2="283.234" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint22_linear_2002_99735" x1="250.889" y1="281.9" x2="216.848" y2="283.224" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint23_linear_2002_99735" x1="86.6414" y1="283.31" x2="111.663" y2="279.943" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BE2200"/>
                        <stop offset="1" stopColor="#B95A02"/>
                    </linearGradient>
                    <linearGradient id="paint24_linear_2002_99735" x1="122.666" y1="283.31" x2="147.687" y2="279.943" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BE2200"/>
                        <stop offset="1" stopColor="#B95A02"/>
                    </linearGradient>
                    <linearGradient id="paint25_linear_2002_99735" x1="158.689" y1="283.31" x2="183.711" y2="279.943" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BE2200"/>
                        <stop offset="1" stopColor="#B95A02"/>
                    </linearGradient>
                    <linearGradient id="paint26_linear_2002_99735" x1="194.714" y1="283.31" x2="219.735" y2="279.943" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BE2200"/>
                        <stop offset="1" stopColor="#B95A02"/>
                    </linearGradient>
                    <linearGradient id="paint27_linear_2002_99735" x1="230.732" y1="283.31" x2="255.612" y2="279.982" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BE2200"/>
                        <stop offset="1" stopColor="#B95A02"/>
                    </linearGradient>
                    <linearGradient id="paint28_linear_2002_99735" x1="164.193" y1="321.097" x2="164.193" y2="292.096" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white"/>
                        <stop offset="1" stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="paint29_linear_2002_99735" x1="98.3318" y1="266.19" x2="110.877" y2="265.465" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint30_linear_2002_99735" x1="218.686" y1="266.19" x2="231.232" y2="265.465" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FF842B"/>
                        <stop offset="1" stopColor="#B3470A"/>
                    </linearGradient>
                    <linearGradient id="paint31_linear_2002_99735" x1="304.97" y1="347.623" x2="304.97" y2="359.747" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#6720FF"/>
                        <stop offset="1" stopColor="#0D069D" stopOpacity="0.94"/>
                    </linearGradient>
                    <linearGradient id="paint32_linear_2002_99735" x1="338.119" y1="347.623" x2="338.119" y2="359.747" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#3C06AF"/>
                        <stop offset="1" stopColor="#0D069D" stopOpacity="0.94"/>
                    </linearGradient>
                    <linearGradient id="paint33_linear_2002_99735" x1="117.655" y1="338.551" x2="124.369" y2="338.7" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                    <linearGradient id="paint34_linear_2002_99735" x1="117.452" y1="350.506" x2="126.919" y2="351.214" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0601646" stopColor="#FE4B4B"/>
                        <stop offset="1" stopColor="#FFAD80"/>
                    </linearGradient>
                </defs>
            </svg>
        </Box>

    );
};

export default ErrorImage;