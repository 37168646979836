import { object, string, ref } from '@softland/forms/validators';

export const loginValidation = object().shape({
  email: string().email().required(),
  password: string().min(8).required(),
});

export const resetValidation = object().shape({
  newPassword: string()
    .matches(RegExp('^(?=.*?[A-Z])'), 'uppercase')
    .matches(RegExp('[`\\/!@#$%^&*()_+={};\':"|,.<>?~]'), 'symbol')
    .min(8)
    .required(),
  confirmPassword: string()
    .oneOf([ref('newPassword'), undefined], "Passwords don't match")
    .required(),
});
