import {Navigate, Route, Routes} from "react-router-dom";
import {Box, useTheme} from '@mui/material';

import Login from "./login/component/Login";
import Reset from "./login/component/Reset";
import Footer from "./login/component/Footer";
import Header from "./login/component/Header";

const Auth = () => {
    const theme = useTheme()
    return (
        <Box sx={{
            height: "100vh",
            backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.paper : "#F5F5F5",
        }}
        className="page"
        >
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                padding: "32px 240px",
            }}
                 className="pageBlock"
            >
                <Header mode={theme.palette.mode}/>
               <Box sx={{
                   zIndex: "1",
                   maxWidth: "400px",
                   height: "auto",
                   bgcolor: theme.palette.mode === "dark" ? theme.palette.background.paper : theme.palette.background.paper,
                   borderRadius: "16px",
               }}
                 className="formBlock"
               >
                   <Routes>
                       <Route path="/" index element={<Navigate to="/login" />}></Route>
                       <Route path="/login" element={<Login />}></Route>
                       <Route path="/reset" element={<Reset />}></Route>
                   </Routes>
               </Box>
                <Footer/>
            </Box>
        </Box>
    );
};

export default Auth;