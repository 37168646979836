import {Typography, Box} from "@mui/material";

import GeometricShapes from "./Geometric-shapaes";

const Footer = () => {

    return (
       <Box>
           <Box>
               <Typography variant="caption" className='qa-softland'>@Softland 2023</Typography>
           </Box>
           <Box sx={{
               position: "absolute",
           }}
           className="pictureWidth"
           >
               <GeometricShapes/>
           </Box>
       </Box>
    );
};

export default Footer;