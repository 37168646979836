import {useMemo} from 'react';
import { useLocation } from "react-router-dom";

import {Box, Typography, useTheme} from '@mui/material';


import Header from "./Header";
import ErrorImage from "./ErrorImage";

const Error= () => {
const theme = useTheme()

    const location = useLocation()

    const message = useMemo(() => {
        if (location.state.errorMessage === "expired") {
            return "Link is expired"
        } else if (location.state.errorMessage === "Wrong Request") {
            return "user is inactive"
        }
        return "page not found"
    }, [location])

    return (
        <Box sx={{
            height: "100vh",
            backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.paper : "#F5F5F5",
        }}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                padding: "32px 240px",
            }}
              className="errorContainer"
            >
                <Header mode={theme.palette.mode}/>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center"
                }}
                >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "60px",
                        alignItems: "center"
                    }}>
                        <ErrorImage/>
                        <Typography variant="h3">Sorry, {message} </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                     <Typography variant="caption" className='qa-softland'>@Softland 2023</Typography>
                 </Box>
            </Box>
        </Box>
    );
};

export default Error;