import { Box } from "@mui/material";

const GeometricShapes = () => {
    return (
        <Box width="100%" height="100%" className='qa-geometric-shapes'>
            <svg width="100%" height="100%" viewBox="0 0 736 736" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8">
                    <rect x="588.8" y="147.2" width="147.2" height="147.2" fill="#CC5FFF"/>
                    <rect x="588.8" y="294.4" width="147.2" height="147.2" fill="white"/>
                    <rect x="588.8" y="441.6" width="147.2" height="147.2" fill="white"/>
                    <rect x="294.4" y="441.6" width="147.2" height="147.2" fill="#FF7E00"/>
                    <rect x="147.2" y="588.8" width="147.2" height="147.2" fill="#CC00FF"/>
                    <rect x="441.6" y="294.4" width="147.2" height="147.2" fill="#CC00FF"/>
                    <path d="M441.6 294.4H588.8L441.6 441.6V294.4Z" fill="#0011AD"/>
                    <path d="M588.8 441.6L736 588.8H588.8V441.6Z" fill="#2B20FF"/>
                    <rect x="294.4" y="294.4" width="73.6" height="147.2" fill="#5125FF"/>
                    <rect x="588.8" y="0.000488281" width="147.2" height="147.2" fill="#0011AD"/>
                    <rect x="441.6" y="147.2" width="73.6" height="147.2" fill="#FFBE00"/>
                    <rect x="368" y="294.4" width="73.6" height="147.2" fill="#CF08FF"/>
                    <rect x="515.2" y="147.2" width="73.6" height="147.2" fill="#CC00FF"/>
                    <path d="M294.4 588.8H147.2L220.8 515.2L294.4 588.8Z" fill="#FF7E00"/>
                    <path d="M588.8 294.4L736 294.4L662.4 368L588.8 294.4Z" fill="#FFBE00"/>
                    <path d="M294.4 441.6L294.4 588.8L220.8 515.2L294.4 441.6Z" fill="#FFAD80"/>
                    <path d="M588.799 441.6L588.799 294.4L662.399 368L588.799 441.6Z" fill="#FF7E00"/>
                    <path d="M700.671 441.6L735.999 476.928V441.6H700.671Z" fill="#CC00FF"/>
                    <path d="M700.671 441.6L735.999 406.272V441.6H700.671Z" fill="#CC00FF"/>
                    <rect x="441.6" y="588.8" width="147.2" height="147.2" fill="#CC00FF"/>
                    <rect x="441.6" y="441.6" width="147.2" height="147.2" fill="#E167FF"/>
                    <path d="M441.6 515.2H515.2V588.8H441.6V515.2Z" fill="#0011AD"/>
                    <path d="M294.4 588.8H441.6V662.4C441.6 703.048 408.648 736 368 736C327.352 736 294.4 703.048 294.4 662.4V588.8Z" fill="#FFAD80"/>
                    <path d="M588.8 147.2L588.8 3.21716e-06L662.4 0C703.048 -1.77679e-06 736 32.9518 736 73.6C736 114.248 703.048 147.2 662.4 147.2H588.8Z" fill="#7D5DF9"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M441.6 441.6H368V588.8C408.648 588.8 441.6 555.848 441.6 515.2V441.6Z" fill="#2B20FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M441.6 441.6H368V588.8C408.648 588.8 441.6 555.848 441.6 515.2V441.6Z" fill="#4616FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M294.4 441.6H368V588.8C327.352 588.8 294.4 555.848 294.4 515.2V441.6Z" fill="#F318A9"/>
                    <path d="M0 736C0 716.669 3.80744 697.528 11.2049 679.669C18.6024 661.81 29.4451 645.583 43.1139 631.914C56.7826 618.245 73.0098 607.402 90.8689 600.005C108.728 592.607 127.869 588.8 147.2 588.8L147.2 736H0Z" fill="#CC5FFF"/>
                    <path d="M441.6 147.2C441.6 127.87 445.407 108.729 452.805 90.8694C460.202 73.0103 471.045 56.7831 484.713 43.1143C498.382 29.4456 514.609 18.6029 532.469 11.2054C550.328 3.80792 569.469 0.000487436 588.8 0.000488281L588.8 147.2H441.6Z" fill="#CC5FFF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M645.131 158.405C627.272 151.007 608.13 147.2 588.8 147.2L588.8 294.4L692.886 190.314C679.217 176.645 662.99 165.802 645.131 158.405Z" fill="#0011AD"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M385.269 283.195C403.128 290.592 422.269 294.4 441.6 294.4L441.6 147.2L337.514 251.286C351.182 264.955 367.41 275.797 385.269 283.195Z" fill="#FF7E00"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M724.795 238.07C717.397 220.211 706.555 203.984 692.886 190.315L588.8 294.401L736 294.401C736 275.071 732.192 255.929 724.795 238.07Z" fill="#4616FF"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M305.605 203.531C313.002 221.39 323.845 237.617 337.514 251.286L441.6 147.2L294.4 147.2C294.4 166.53 298.207 185.672 305.605 203.531Z" fill="#F318A9"/>
                    <path d="M736 588.8C736 608.131 732.192 627.272 724.795 645.131C717.397 662.99 706.555 679.217 692.886 692.886C679.217 706.555 662.99 717.398 645.131 724.795C627.272 732.193 608.13 736 588.8 736L588.8 588.8L736 588.8Z" fill="#FFAD80"/>
                    <path d="M73.6001 736C73.6001 726.334 75.5038 716.764 79.2026 707.834C82.9013 698.905 88.3226 690.791 95.157 683.957C101.991 677.122 110.105 671.701 119.035 668.002C127.964 664.303 137.535 662.4 147.2 662.4V736H73.6001Z" fill="#6E00B1"/>
                    <path d="M515.2 147.201C515.2 137.535 517.103 127.965 520.802 119.035C524.501 110.105 529.922 101.992 536.757 95.1575C543.591 88.3231 551.705 82.9018 560.634 79.203C569.564 75.5043 579.134 73.6006 588.8 73.6006V147.201H515.2Z" fill="#FFBE00"/>
                    <path d="M588.799 73.6006C598.465 73.6006 608.035 75.5043 616.965 79.203C625.895 82.9018 634.008 88.3231 640.842 95.1575C647.677 101.992 653.098 110.106 656.797 119.035C660.496 127.965 662.399 137.535 662.399 147.201L588.799 147.201L588.799 73.6006Z" fill="#0011AD"/>
                    <path d="M588.799 515.2C598.465 515.2 608.035 517.103 616.965 520.802C625.895 524.501 634.008 529.922 640.842 536.757C647.677 543.591 653.098 551.705 656.797 560.634C660.496 569.564 662.399 579.134 662.399 588.8L588.799 588.8L588.799 515.2Z" fill="#FFBE00"/>
                    <path d="M662.4 588.8C662.4 598.465 660.496 608.036 656.797 616.966C653.099 625.895 647.677 634.009 640.843 640.843C634.008 647.677 625.895 653.099 616.965 656.798C608.036 660.496 598.465 662.4 588.8 662.4L588.8 588.8L662.4 588.8Z" fill="#2A02C8"/>
                    <circle cx="699.2" cy="382.72" r="8.83199" fill="#0011AD"/>
                    <circle cx="179.584" cy="621.184" r="8.83199" fill="white"/>
                    <circle cx="220.8" cy="621.184" r="8.83199" fill="white"/>
                    <circle cx="262.016" cy="621.184" r="8.83199" fill="white"/>
                    <circle cx="473.984" cy="478.4" r="8.83199" fill="white"/>
                    <circle cx="515.2" cy="478.4" r="8.83199" fill="white"/>
                    <circle cx="556.415" cy="478.4" r="8.83199" fill="white"/>
                    <circle cx="669.759" cy="413.632" r="8.83199" fill="#0011AD"/>
                    <circle cx="179.584" cy="662.4" r="8.83199" fill="white"/>
                    <circle cx="220.8" cy="662.4" r="8.83199" fill="white"/>
                    <circle cx="262.016" cy="662.4" r="8.83199" fill="white"/>
                    <circle cx="640.32" cy="441.6" r="8.83199" fill="#0011AD"/>
                    <circle cx="478.4" cy="262.016" r="8.83199" fill="white"/>
                    <circle cx="478.4" cy="179.584" r="8.83199" fill="white"/>
                    <circle cx="478.4" cy="220.8" r="8.83199" fill="white"/>
                    <circle cx="478.4" cy="262.016" r="8.83199" fill="white"/>
                    <circle cx="257.6" cy="326.784" r="8.83199" fill="#FF7E00"/>
                    <circle cx="257.6" cy="368" r="8.83199" fill="#FF7E00"/>
                    <circle cx="257.6" cy="409.216" r="8.83199" fill="#FF7E00"/>
                    <circle cx="179.584" cy="703.616" r="8.83199" fill="white"/>
                    <circle cx="220.8" cy="703.616" r="8.83199" fill="white"/>
                    <circle cx="262.016" cy="703.616" r="8.83199" fill="white"/>
                    <circle cx="552" cy="179.584" r="8.83199" fill="white"/>
                    <circle cx="552" cy="220.8" r="8.83199" fill="white"/>
                    <circle cx="552" cy="262.016" r="8.83199" fill="white"/>
                    <circle cx="669.759" cy="472.512" r="8.83199" fill="#0011AD"/>
                    <circle cx="697.727" cy="503.424" r="8.83199" fill="#0011AD"/>
                    <circle cx="368" cy="368" r="36.8" fill="#1E0094"/>
                    <circle cx="515.2" cy="662.4" r="36.8" fill="white"/>
                    <circle cx="515.2" cy="368" r="36.8" fill="#D9D9D9"/>
                    <circle cx="515.2" cy="368" r="36.8" fill="#D9D9D9"/>
                    <circle cx="368" cy="515.2" r="36.8" fill="white"/>
                    <circle cx="515.2" cy="368" r="73.6" fill="white"/>
                    <circle cx="515.2" cy="368" r="36.8" fill="#6E00B1"/>
                    <path d="M441.6 515.2H552C572.324 515.2 588.8 531.676 588.8 552V588.8H478.4C458.076 588.8 441.6 572.324 441.6 552V515.2Z" fill="#FF7E00"/>
                </g>
            </svg>
        </Box>
    );
};

export default GeometricShapes;