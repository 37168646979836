import { useState, MouseEvent} from "react";
import {useLocation} from "react-router-dom";
import { SubmitHandler, useForm } from "@softland/forms";
import { yupResolver } from "@softland/forms/resolver";
import {
    Box,
    Typography,
    TextField,
    IconButton,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormControl,
    useTheme
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from '@mui/lab/LoadingButton';



import {login} from "modules/auth/api/auth.api";
import { loginValidation } from "./validation";


interface LoginForm {
    email: string;
    password: string;
}
const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const theme = useTheme()
    const location = useLocation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginForm>({
        mode: 'onBlur',
        resolver: yupResolver(loginValidation),
    });
    const onSubmit: SubmitHandler<LoginForm> = async(data) => {
        setLoading(true);
        setErrorMessage('')
        const queryParams = new URLSearchParams(location.search);
        await login({
            username: data.email,
            password: data.password,
            returnurl: queryParams.get('ReturnUrl') || '',
            rememberlogin: true
        }).then((response) => {
            const returnUrl  = queryParams.get('ReturnUrl') || response.data.returnUrl;
            if (returnUrl) {
                window.location.replace(returnUrl);
            }
            else {
                window.location.replace(response.data);
            }
        }).catch((error) => {
            switch (error.response.status) {
                case 401:
                    if(error.response.data.message === "User is Blocked") {
                        setErrorMessage(error.response.data.message)
                    } else {
                        setErrorMessage('You have entered an invalid password');
                    }
                    break;
                case 404:
                    setErrorMessage('You have entered an invalid username or password');
                    break;
                case 1:
                    setErrorMessage('Your account is blocked');
                    break;
                default:
                    console.log(error.message)
            }
        });
        setLoading(false);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };
    return (
        <Box
            sx={{
                padding: "24px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.09)' : 'none'
            }}
        >
            <Box>
                <Typography variant="h4" className='qa-login-title'>Welcome Back!</Typography>
                <Typography
                    variant="subtitle1"
                    sx={{ color: theme.palette.text.secondary, marginTop: "7px", marginBottom: "15px" }}
                    className='qa-welcome-message'
                >
                    Welcome back! Please enter your details
                </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
                    <TextField
                        sx={{
                            width: "100%",
                            marginBottom: "20px",
                        }}
                        size="medium"
                        id="qa-login-email"
                        label="Email"
                        variant="outlined"
                        tabIndex={1}
                        error={!!errors.email}
                        {...register("email")}
                    />

                    <FormControl
                        sx={{ width: "100%" }}
                        variant="outlined"
                        error={!!errors.password}
                    >
                        <InputLabel htmlFor="qa-login-password">Password</InputLabel>
                        <OutlinedInput
                            {...register("password")}
                            size="medium"
                            id="qa-login-password"
                            tabIndex={2}
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        className="qa-login-password-visibility-btn"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff className='qa-visibility-off-icon' /> : <Visibility className='qa-visibility-on-icon'/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                { errorMessage &&  <Typography variant="caption" sx={{ color: theme.palette.error.main, padding: "10px" }} className='qa-error-message'>
                {errorMessage}
            </Typography> }
            </Box>
            <LoadingButton
                id="qa-login-button"
                loading={loading}
                sx={{ width: "100%", height: "42px", marginTop: "20px" }}
                size="medium"
                variant="contained"
                type='submit'
            >
                login
            </LoadingButton>
        </form>
        </Box>
    );
};

export default Login;