import {httpClient, resetClient} from "modules/common/api/http-client";

export interface LoginRequestModel {
    username: string;
    password: string;
    returnurl: string;
    rememberlogin: boolean
}

export function login (data: LoginRequestModel) {
    return httpClient.post('/api/v1/acc/login', data)
}

export const resetApi = {
    async resetPassword (sendPassword: {password: string, code: string}) {
        return await resetClient.post(
            `/passwordcode/${sendPassword.code}`,
            { password:sendPassword.password }
        );
    },
    async getResetPasswordCode (code: string) {
        return resetClient.get(`/passwordcode/${code}`)
    }
}