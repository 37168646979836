import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material";
import {lightTheme} from "@softland/design-system";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Auth from "modules/auth/Auth"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Error from "./modules/auth/login/component/Error";

import "./index.css"

const queryLogin = new QueryClient();

function App() {

  return (
  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <BrowserRouter>
      <QueryClientProvider client={queryLogin}>
        <Routes>
          <Route path="*" element={<Auth/>}></Route>
          <Route path="/error" element={<Error />}></Route>
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  </ThemeProvider>
  );
}

export default App;
